nav {
  background: white;

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    height: 3.5rem;

    @include for-phone-up() {
      height: 3.9rem;
    }

    @include for-tablet-portrait-up {
      height: 4rem;
    }

    @include for-tablet-landscape-up {
      height: 4.2rem;
    }

    @include for-desktop-up {
      height: 4.3rem;
    }

    @include for-medium-desktop-up {
      height: 4.3rem;
    }

    @include for-big-desktop-up {
      height: 4.5rem;
    }

    @include for-large-desktop-up {
      height: 4.5rem;
    }

    .logo {
      height: 100%;

      img {
        height: 100%;
      }
    }

    .language_selector {
      display: inline-block;
      padding: 0.3rem;
      color: white;

      .language {
        font-size: 0.8rem;
        padding: 0.2rem 0.5rem 0.3rem;
      }

      .active {
        cursor: default;
        background: #69b72c;
      }

      :not(:last-child) {
        margin-right: 0.3rem;
      }
    }
  }
}
