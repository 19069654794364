::backdrop {
  background-color: white;
}

.video-learning-container:fullscreen,
.video-learning-container:-webkit-fullscreen,
.video-learning-container:-moz-full-screen {
  background-color: rgba(0, 0, 0, 0.6);
}

.video-learning-container {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  z-index: 333;
  background: rgba(0, 0, 0, 0.7);
}

.elearn-question-media {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.elearn-question-media img,
audio,
video {
  width: 650px;
}

.elearn-question-media audio::-webkit-media-controls-panel {
  background-color: #f38181;
}

.elearn-background {
  background-color: #eff3f7;
  width: 100%;
  height: 100%;
}

.elearn-header {
  background-color: #247891;
  color: white;
  width: 100%;
  height: 70px;
  margin: 0;
}

.elearn-question-count {
  display: block;
}

.elearn-header-understroke {
  background-color: #f38181;
  height: 10px;
  width: 100%;
  margin: 0;
}

.elearn-form {
  height: calc(100% - 80px);
}

.elearn-close svg {
  cursor: pointer;
}

#elearn-pre {
  position: absolute;
  left: 45%;
  top: 45%;
  z-index: 1;
  width: 120px;
  height: 120px;
  border: 10px solid white;
  border-radius: 50%;
  border-top: 10px solid #f38181;
  -webkit-animation: spin 0.35s linear infinite;
  animation: spin 0.35s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.elearn-body {
  display: flex;
  height: calc(100% - 80px);
  width: 100%;
}

.elearn-finished {
  position: absolute;
  background: white;
  z-index: 1;
  height: 100%;
  width: 100%;
}

.elearn-finished-text {
  text-align: center;
  margin-top: 25% !important;
}

.elearn-question-section {
  color: #6d6a67;
}

.elearn-form-row-h {
  height: calc(100% - 90px);
}

.elearn-choice-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin: 15px 0 12px 35px !important;
  cursor: pointer;
  font-size: 17px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.elearn-choice-body {
  height: 60px;
  width: 400px;
  border-radius: 10px;
  border: 1.5px solid #c4c5c4;
  margin: 10px 0 !important;
}

.elearn-choice-body:hover {
  background-color: #c4c5c4;
}

.elearn-choice-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.elearn-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid #c4c5c4;
}

.elearn-checkmark-box {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
}

.elearn-checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

.elearn-checkmark-box::after {
  content: "";
  position: absolute;
  display: none;
}

.elearn-choice-container input:checked ~ .elearn-checkmark::after {
  display: block;
}

.elearn-choice-container input:checked ~ .elearn-checkmark-box {
  background: #719fb2;
}

.elearn-choice-container input:checked ~ .elearn-checkmark-box::after {
  display: block;
}

.elearn-choice-container .elearn-checkmark::after {
  top: 2px;
  left: 2px;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background: #719fb2;
}

.elearn-choice-container .elearn-checkmark-box::after {
  top: 3px;
  left: 8px;
  width: 9px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.elearn-bottom {
  margin: 0 25% !important;
}

.elearn-button-background {
  position: absolute;
  height: 300px;
  width: 100%;
  left: 0;
  bottom: 0;
  transform: translateY(-74.4%);
}

.next-button {
  position: relative;
  top: 100%;
  transform: translateY(230%);
  background-color: #669db2 !important;
  border: none;
  color: white !important;
  padding: 15px 75px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 10px;
}

.elearn-choice-feedback {
  position: relative;
  top: 100%;
  transform: translateY(-990%);
}

.disabled {
  background-color: #c4c5c4;
}

.disabled-input {
  cursor: default !important;
}

/* E-Learner - Wrong and correct classes for answers */

/* Correct */
.elearn-choice-body-correct {
  border: 1.5px solid #8baa00;
  background-color: #d5e9a4;
}

.elearn-choice-body-correct:hover {
  background-color: #d5e9a4;
}

.elearn-button-correct {
  background-color: #8baa00 !important;
}

.elearn-button-background-correct {
  background-image: linear-gradient(to bottom, rgba(213, 233, 164, 0), rgba(213, 233, 164, 1));
}

.elearn-choice-feedback-correct {
  color: #8baa00;
}

/* Wrong */
.elearn-choice-body-wrong {
  border: 1.5px solid #ca5a55;
  background-color: #e8b4be;
}

.elearn-choice-body-wrong:hover {
  background-color: #e8b4be;
}

.elearn-button-wrong {
  background-color: #eb6256 !important;
}

.elearn-button-background-wrong {
  background-image: linear-gradient(to bottom, rgba(232, 180, 190, 0), rgba(232, 180, 190, 1));
}

.elearn-choice-feedback-wrong {
  color: #eb6256;
}
