.lead-container {
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;
  border-top: 1rem $Fourth-color solid;
  border-bottom: 1rem $Fourth-color solid;

  @include for-phone-up {
    height: 450px;
  }

  @include for-tablet-portrait-up {
    height: 455px;
  }

  @include for-tablet-landscape-up {
    height: 530px;
  }

  @include for-desktop-up {
    height: 580px;
  }

  @include for-medium-desktop-up {
    height: 680px;
  }

  @include for-big-desktop-up {
    height: 700px;
  }

  @include for-large-desktop-up {
    height: 700px;
  }

  .lead-video {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -2;
  }

  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: -1;
  }

  .video-overlay {
    background: #3ba5e3b3;
    width: 100%;
  }

  .layoutContainer {
    display: flex;
    align-items: center;
    height: 100%;

    .landing_text {
      padding: 0.5rem 0 1.5rem;
      color: white;
      width: fit-content;

      @include for-tablet-portrait-up {
        width: 70%;
      }

      @include for-tablet-landscape-up {
        width: 50%;
      }

      @include for-desktop-up {
        width: 40%;
      }

      .landing_title {
        font-style: oblique;
        padding: 0;
        font-weight: 100;
        font-size: 2rem;

        @include for-phone-up {
          font-size: 2.2rem;
        }

        @include for-tablet-portrait-up {
          font-size: 2.5rem;
        }

        @include for-tablet-landscape-up {
          font-size: 2.7rem;
        }

        @include for-medium-desktop-up {
          font-size: 2.8rem;
        }

        @include for-desktop-up {
          font-size: 3rem;
        }

        @include for-big-desktop-up {
          font-size: 3.5rem;
        }

        // @include for-large-desktop-up {}
      }

      .call_to_action_btn {
        display: block;
        width: fit-content;

        @include for-phone-up {
          margin-top: 2.4rem;
        }

        @include for-tablet-portrait-up {
          margin-top: 2.5rem;
        }

        @include for-tablet-landscape-up {
          margin-top: 2.5rem;
        }

        @include for-medium-desktop-up {
          margin-top: 2.5rem;
        }

        @include for-desktop-up {
          margin-top: 2.6rem;
        }

        @include for-big-desktop-up {
          margin-top: 2.8rem;
        }

        @include for-large-desktop-up {
          margin-top: 2.9rem;
        }
      }

      .landing_list {
        padding: 0.5rem 0.7rem 0;
        margin-bottom: 1.4rem;
        position: relative;

        @include for-phone-up {
          font-size: 1.2rem;
          padding: 1.5rem 1rem 0;
        }

        @include for-tablet-portrait-up {
          font-size: 1.2rem;
        }

        @include for-tablet-landscape-up {
          font-size: 1.2rem;
        }

        @include for-medium-desktop-up {
          font-size: 1.2rem;
        }

        @include for-desktop-up {
          font-size: 1.2rem;
        }

        @include for-big-desktop-up {
          font-size: 1.2rem;
        }

        @include for-large-desktop-up {
          font-size: 1.2rem;
        }

        .landing_list_item {
          margin-bottom: 0.3rem;
          position: relative;

          &::before {
            content: "";
            width: 0.3rem;
            height: 0.3rem;
            position: absolute;
            border-radius: 50%;
            background: $Fourth-color;
            top: 0.5rem;
            left: -0.7rem;

            @include for-phone-up() {
              width: 0.4rem;
              height: 0.4rem;
              top: 0.6rem;
              left: -1rem;
            }

            // @include for-tablet-portrait-up {}

            // @include for-tablet-landscape-up {}

            // @include for-desktop-up {
            // }

            // @include for-medium-desktop-up {
            // }

            // @include for-big-desktop-up {}

            // @include for-large-desktop-up {}
          }

          p {
            margin: 0;
          }
        }
      }
    }
  }
}
