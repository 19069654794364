.related-videos {
  .wrap-carousel {
    .container-carousel {
      .carousel_slider {
        .carousel_item {
          width: 255px;

          .card {
            margin: 0.5rem;

            .thumbnail_container {
              .thumbnail {
                width: 100%;
              }
            }

            .leaflet-button {
              color: $Seventh-color;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;

              &:hover {
                color: $Fifth-color;
              }
            }
          }
        }
      }

      .react-multiple-carousel__arrow {
        @include for-phone-only {
          top: calc(87% - 15px);
        }
      }

      .react-multiple-carousel__arrow--left {
        @include for-phone-only {
          left: calc(5% + 1px);
        }
      }

      .react-multiple-carousel__arrow--right {
        @include for-phone-only {
          right: calc(5% + 1px);
        }
      }
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.related-videos-container {
  margin: 0 -0.5rem;
}
