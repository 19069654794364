.cookie-disclaimer {
  position: fixed;
  left: 0;
  height: fit-content;
  background: white;
  z-index: 99999;
  transform: translateY(-100%);
  transition: transform 0.5s ease-in-out;
  bottom: -18.5rem;
  width: 100%;
  box-shadow:
    7px 5px 10px 15px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);

  @include for-phone-up() {
    bottom: -18.5rem;
  }

  @include for-tablet-portrait-up {
    bottom: -14.5rem;
  }

  @include for-tablet-large-portrait-up {
    bottom: -10rem;
  }

  @include for-tablet-landscape-up {
    bottom: -10rem;
  }

  @include for-desktop-up {
    bottom: -8rem;
  }

  @include for-medium-desktop-up {
    bottom: -7rem;
  }

  @include for-big-desktop-up {
    bottom: -6rem;
  }

  @include for-large-desktop-up {
    bottom: -6rem;
  }

  .layoutContainer {
    display: flex;
    color: rgb(71, 71, 71);
    height: 18.5rem;
    flex-direction: column;

    @include for-phone-up() {
      height: 18.5rem;
    }

    @include for-tablet-portrait-up {
      height: 14.5rem;
    }

    @include for-tablet-large-portrait-up {
      height: 10rem;
      flex-direction: row;
    }

    @include for-tablet-landscape-up {
      height: 10rem;
    }

    @include for-desktop-up {
      height: 8rem;
    }

    @include for-medium-desktop-up {
      height: 7rem;
    }

    @include for-big-desktop-up {
      height: 6rem;
    }

    @include for-large-desktop-up {
      height: 6rem;
    }

    .cookie-heading {
      display: flex;
      align-items: center;

      h3 {
        margin: 0;
        padding: 1rem 0;
        font-weight: bold;
        border-bottom: 1px solid rgb(71, 71, 71);
        width: 100%;

        @include for-tablet-large-portrait-up {
          border-right: 1px solid rgb(71, 71, 71);
          padding: 2.5rem 2rem 2.5rem 0;
          border-bottom: none;
          width: fit-content;
        }

        @include for-desktop-up {
          padding: 1.5rem 2rem 1.5rem 0;
        }
      }
    }

    .cookie-body {
      display: flex;
      align-items: center;
      margin: 0.5rem 0 1rem;

      @include for-tablet-large-portrait-up {
        padding: 1.5rem 2rem 1.5rem 0;
        border-bottom: none;
        width: fit-content;
        margin-right: 4rem;
        margin-left: 2rem;
      }

      p {
        margin: 0;
        font-size: 0.9rem;
        line-height: 1.3rem;

        a {
          color: darkblue !important;
          cursor: pointer;
        }
      }
    }

    .acceptButton {
      display: flex;
      align-items: center;

      .km_btn {
        font-size: 0.8rem;
      }
    }
  }
}

.cookie-disclaimer-accept {
  transform: translateY(100%);
}

.cookie-disclaimer-accepted {
  display: none;
}

.cookiesInfoModal {
  .modal-lg {
    max-width: 1500px;

    @include for-tablet-portrait-up {
      padding: 1rem;
      padding-left: 1rem !important;
    }

    .modal-content {
      .modal-header {
        padding: 0.6rem 2rem 0.9rem;

        .modal-title {
          font-size: 2rem;
        }
      }

      .modal-body {
        padding: 1rem 2rem;

        @include for-desktop-up {
          display: flex;
          flex-wrap: wrap;
        }

        .generalInfo {
          @include for-desktop-up {
            width: 25%;
            padding-right: 1.5rem;
            line-height: 1.4rem;
          }
        }

        .inDepthInfo {
          @include for-desktop-up {
            width: 75%;
          }

          .table {
            display: table;
            font-size: 0.9rem;
            margin: 0;

            .grid-row {
              display: block;
              margin-bottom: 1rem;
              padding-bottom: 1rem;
              border-bottom: 1px solid rgb(199, 199, 199) !important;

              .cell::before {
                content: attr(data-title);
                line-height: 1.3rem;
                font-size: 0.8rem;
                font-weight: bold;
                width: 100%;
                text-transform: uppercase;
                color: rgb(190, 190, 190);
                display: block;
              }

              @include for-tablet-large-portrait-up {
                display: table-row;

                .cell::before {
                  display: none;
                }
              }

              .cell {
                display: block;
                padding: 0 0.5rem;

                @include for-tablet-large-portrait-up {
                  display: table-cell;
                  border-bottom: 1px solid rgb(172, 172, 172);
                  padding: 0.2rem;
                }
              }
            }

            @include for-tablet-portrait-up {
              margin-bottom: 1rem;
            }

            @include for-tablet-large-portrait-up {
              border: rgb(141, 141, 141) 1px solid;
              padding: 1rem;
              border-radius: 1rem;

              .grid-row:nth-of-type(odd) {
                background: rgb(226, 226, 226);
              }
            }

            .grid-row.header {
              display: none;

              @include for-tablet-large-portrait-up {
                display: table-row;
                background: white;
                font-weight: bold;
              }
            }
          }
        }

        .question {
          width: 100%;

          p {
            font-weight: bold;
          }
        }

        .km_btn {
          font-size: 0.9rem;
          margin-bottom: 2rem;
        }
      }
    }
  }
}
