.video-extra-info-container {
  margin-bottom: 2rem;
}

.indication-substance-list {
  padding: 0;
}

.image_link_image,
.related_video_image {
  width: 100%;
}

.video-leaflet-link-buttons {
  padding: 0;

  li {
    margin-bottom: 0.5rem;
    color: $Fourth-color;

    &:hover {
      color: white;
    }

    .link {
      background: #d9d9d9;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      display: inline-block;
      width: 100%;
      text-align: center;
      border: none;
      color: $Fifth-color;

      svg {
        height: 0.9rem;
      }

      span {
        font-size: 0.9rem;
      }

      &:hover {
        color: white;
        background: $Fifth-color;
      }
    }
  }
}

.video-leaflet-description {
  color: #669db2;
}

.video-leaflet-share-button {
  padding: 10px;
  color: $Fifth-color;
  font-size: 1.33rem;

  &:hover {
    color: $Seventh-color;
  }
}

.leaflet-video-title {
  padding-top: 0.5rem;
  color: rgb(73, 73, 73);
}

.video-thumbnail-container {
  cursor: pointer;
  position: relative;

  .video-thumbnail-play-btn {
    position: absolute;
    inset: 0;
    margin: auto;
  }
}

.related-videos-link-title {
  margin: 0;
}

.medium-margin-bottom {
  margin-bottom: 1rem;
}

.video-learner-button {
  cursor: pointer;
}

.react-multiple-carousel__arrow {
  min-width: 28px;
  min-height: 28px;
}

.related-videos-link-container {
  // .related-videos-link-image {
  //   float: left;
  //   height: 100%;
  //   margin-right: -20px;
  //   z-index: 1;
  //   top: 0;
  //   margin-left: 20px;
  //   left: 0;
  //   position: absolute;
  // }
  .related-videos-link-image {
    max-height: 72px;
  }
  

  .video-leaflet-link-buttons {
    margin-left: 50px;
    z-index: -55;
    width: 100%;
    transform: translateY(25%);

    li {
      padding: 0;
    }

    a {
      width: auto;
      padding-left: 50px;
      white-space: nowrap;
    }
  }

  .leaflet-related-video-carousel {
    .react-multi-carousel-list {
      .react-multiple-carousel__arrow {
        top: calc(50% - 35px);
      }
    }
  }
}
