.footer {
  background: #464646;
  border-top: 1rem $Fourth-color solid;

  .footerContent {
    color: white;
    padding-top: 3.5rem;

    @include for-tablet-portrait-up {
      display: flex;
      flex-wrap: wrap;
      row-gap: 1rem;
    }

    .hpt-input-field {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 0;
      width: 0;
      z-index: -1;
    }

    .footerContactForm {
      @include for-tablet-portrait-up {
        padding-right: 1rem;
        margin: calc(#{$gutter-with} / 2);
        width: calc(50% - #{$gutter-with});
      }

      @include for-desktop-up {
        width: calc(33.3333% - #{$gutter-with});
      }

      .footer_contact {
        .form-group {
          position: relative;

          input {
            height: 1.8rem;
          }

          .small-text-error {
            font-size: 0.85rem;
            color: rgb(254, 62, 62);
            padding: 0.5rem 0 0;
            margin: 0;
          }

          .compulsory {
            position: relative;

            &::after {
              content: "*";
              position: absolute;
              width: fit-content;
              height: fit-content;
              font-size: 0.7rem;
              padding-left: 0.1rem;
              top: 0;
              right: -0.5rem;
            }
          }

          .error {
            color: rgb(255, 0, 0);
            padding: 0.5rem 0 0;
            margin: 0;
          }
        }
      }
    }

    .footerContactInfo {
      @include for-tablet-portrait-up {
        padding-top: 0;
        margin: calc(#{$gutter-with} / 2);
        width: calc(50% - #{$gutter-with});
      }

      @include for-desktop-up {
        width: calc(33.3333% - #{$gutter-with});
      }
    }

    .footerSocialMediaInfo {
      padding-top: 2rem;

      @include for-phone-up {
        padding-bottom: 2rem;
      }

    @include for-tablet-portrait-up {
      padding-top: 0;
      padding-left: 2rem;
      margin: calc(#{$gutter-with} / 2);
      width: calc(50% - #{$gutter-with});
    }

    @include for-desktop-up {
      width: calc(33.3333% - #{$gutter-with});
    }

    .social_block {
      display: block;
      padding-top: 0.4rem;

      .socialMediaLink {
        display: flex;
        padding-bottom: 1rem;
        font-size: 1.5rem;

        .social {
          width: 1.2em;
        }

        .username {
          font-size: 1rem;
          color: white;
          padding-left: 0.5rem;
        }
      }
    }
  }
}
}