.introduction {

  // .intro_title {}
  @include for-tablet-portrait-up {
    display: flex;
    flex-wrap: wrap;
  }

  video {
    width: 100%;
  }

  .intro_video {
    width: 100%;

    @include for-phone-up() {
      width: 100%;
    }

    @include for-tablet-portrait-up {
      width: 100%;
    }

    @include for-tablet-landscape-up {
      width: calc(50% - 0.5rem);
      margin-right: 0.5rem;
      padding-right: 1rem;
    }

    @include for-desktop-up {
      width: calc(50% - 0.5rem);
    }

    @include for-medium-desktop-up {
      width: calc(50% - 0.5rem);
    }

    // @include for-big-desktop-up {}

    // @include for-large-desktop-up {}
  }

  .intro_text {
    padding-top: 1rem;

    @include for-phone-up() {
      width: 100%;
    }

    @include for-tablet-portrait-up {
      width: 100%;
    }

    @include for-tablet-landscape-up {
      margin-left: 0.5rem;
      padding-left: 1rem;
      padding-top: 0;
      width: calc(50% - 0.5rem);
    }

    @include for-desktop-up {
      width: calc(50% - 0.5rem);
    }

    .intro_title {
      padding-bottom: 0.4rem;
    }

    // @include for-medium-desktop-up {}

    // @include for-big-desktop-up {}

    // @include for-large-desktop-up {}

    .disclaimer {
      color: $Fifth-color;
    }

    .find-rvg-code-btn {
      background: none;
      border: none;
      padding: 0;
      color: $Fifth-color;

      // @include for-phone-up() {}

      // @include for-tablet-portrait-up {}

      // @include for-tablet-landscape-up {}

      .icon {
        color: $Fifth-color;
      }
    }
  }
}

.RVG_code_img {
  width: 100%;
  margin-bottom: 1rem;
}
