* {
  box-sizing: border-box;

  &:focus {
    outline: none !important;
  }
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-family: "calibri", sans-serif;
  line-height: 1.3;
  margin: 0;
  padding: 0;
  background: rgb(230, 230, 230);
}

html {
  scroll-behavior: smooth;
  font-size: 17px;

  @include for-phone-up {
    font-size: 17px;
  }

  @include for-tablet-portrait-up {
    font-size: 17px;
  }

  @include for-tablet-landscape-up {
    font-size: 18px;
  }

  @include for-desktop-up {
    font-size: 19px;
  }

  @include for-medium-desktop-up {
    font-size: 20px;
  }

  @include for-big-desktop-up {
    font-size: 21px;
  }

  @include for-large-desktop-up {
    font-size: 22px;
  }
}

h1 {
  font-size: 1.55rem;
  font-weight: bold;

  @include for-phone-up {
    font-size: 1.65rem;
  }

  @include for-tablet-portrait-up {
    font-size: 1.75rem;
  }

  @include for-tablet-landscape-up {
    font-size: 1.8rem;
  }

  @include for-desktop-up {
    font-size: 1.9rem;
  }

  @include for-medium-desktop-up {
    font-size: 1.9rem;
  }

  @include for-big-desktop-up {
    font-size: 1.9rem;
  }

  @include for-large-desktop-up {
    font-size: 1.9rem;
  }
}

h2 {
  font-size: 1.9rem;

  @include for-phone-up {
    font-size: 1.9rem;
  }

  @include for-tablet-portrait-up {
    font-size: 1.9rem;
  }

  @include for-tablet-landscape-up {
    font-size: 1.9rem;
  }

  @include for-medium-desktop-up {
    font-size: 1.9rem;
  }

  @include for-desktop-up {
    font-size: 1.9rem;
  }

  @include for-big-desktop-up {
    font-size: 2rem;
  }

  @include for-large-desktop-up {
    font-size: 2.1rem;
  }
}

h3 {
  font-size: 1.3rem;

  @include for-phone-up {
    font-size: 1.3rem;
  }

  @include for-tablet-portrait-up {
    font-size: 1.3rem;
  }

  @include for-tablet-landscape-up {
    font-size: 1.3rem;
  }

  @include for-medium-desktop-up {
    font-size: 1.35rem;
  }

  @include for-desktop-up {
    font-size: 1.4em;
  }

  @include for-big-desktop-up {
    font-size: 1.45rem;
  }

  @include for-large-desktop-up {
    font-size: 1.5rem;
  }
}

h4 {
  font-size: 1.1rem;

  @include for-phone-up {
    font-size: 1.1rem;
  }

  @include for-tablet-portrait-up {
    font-size: 1.1rem;
  }

  @include for-tablet-landscape-up {
    font-size: 1.1rem;
  }

  @include for-medium-desktop-up {
    font-size: 1.15rem;
  }

  @include for-desktop-up {
    font-size: 1.2em;
  }

  @include for-big-desktop-up {
    font-size: 1.25rem;
  }

  @include for-large-desktop-up {
    font-size: 1.3rem;
  }
}

ul,
li {
  list-style-type: none;
}

.scroll_to {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 10;

  @include for-phone-up {
    bottom: 1.5rem;
    right: 1.5rem;
  }

  .scroll_to_btn {
    &::before {
      background: white;
      border-radius: 50px;
      content: "";
      width: 1.25rem;
      position: absolute;
      height: 1.25rem;
      z-index: -1;
      top: 0.2rem;
      left: 0.1rem;
    }

    svg {
      font-size: 1.5rem;
      color: $Fifth-color;

      &:hover {
        color: $Seventh-color;
      }
    }
  }
}

img {
  display: block;
}

.layoutContainer {
  margin: 0 auto;
  width: 98%;
  padding: 5px 10px;
  max-width: 1600px;

  @include for-phone-up {
    width: 90%;
    margin: 0 auto;
  }

  // @include for-tablet-portrait-up {}

  // @include for-medium-desktop-up {}

  // @include for-desktop-up {}

  // @include for-big-desktop-up {}
}

.km_btn {
  padding: 0.3rem 0.9rem 0.5rem;
  border-radius: 20px;
  background: $Fifth-color;
  color: white;
  cursor: pointer;
  border: none;
  font-size: 1rem;

  &:hover {
    color: white;
    background: $Seventh-color;
  }
}

.km_title {
  color: $Fifth-color;
}

.km_bold {
  font-weight: bold;
}

.section-padding {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.section-padding-top {
  padding-top: 4rem;
}

.section-padding-bottom {
  padding-bottom: 4rem;
}

.line {
  height: 2px;
  background: $Fifth-color;
  display: block;
}

a {
  text-decoration: none !important;
  // color: inherit !important;
  -webkit-appearance: button-bevel !important;
}

.modal-header {
  padding: 0.5rem 1rem 0.3rem;

  .modal-title {
    color: $Fifth-color;
  }

  .close {
    padding: 0.3rem;
    margin: 0;
    line-height: 0.9rem;
    transform-origin: 0.7rem 0.9rem;
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: rotate(180deg);
    }
  }
}

@media (min-width: 992px) {
  .modal-xl {
    max-width: 850px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1170px;
  }
}

@media (min-width: 1600px) {
  .modal-xl {
    max-width: 1550px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.cursor-pointer {
  cursor: pointer;
}
