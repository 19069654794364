$First-color: #247891;
$Second-color: #669db2;
$Third-color: #fefaec;
$Fourth-color: #f38181;
$Fifth-color: #ff4370;
$Sixth-color: #4d4d4d;
$Seventh-color: #69b72c;

$Website-bg-color: #f1f1f1;

@mixin for-phone-only {
  @media (max-width: 320px) {
    @content;
  }
}

@mixin for-phone-up {
  @media (min-width: 400px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin for-tablet-large-portrait-up {
  @media (min-width: 750px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin for-medium-desktop-up {
  @media (min-width: 1600px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-large-desktop-up {
  @media (min-width: 2100px) {
    @content;
  }
}

.break-point {
  position: fixed;
  top: 5px;
  left: calc(50vw - 75px);
  font-size: 12px;

  &::after {
    color: white;
    background: rgb(116, 31, 31);
    position: absolute;
    content: "480";
    width: 150px;
    height: 24px;
    text-align: center;
    padding-top: 5px;
    border-radius: 10px;
    font-size: 12px;

    @include for-phone-up() {
      background: rgb(79, 175, 35);
      content: "Phone-up";
    }

    @include for-tablet-portrait-up {
      background: rgb(175, 161, 35);
      content: "Tablet protrait up";
    }

    @include for-tablet-large-portrait-up {
      background: rgb(58, 99, 126);
      content: "Tablet large protrait up";
    }

    @include for-tablet-landscape-up {
      background: rgb(175, 114, 35);
      content: "Tablet langscape up";
    }

    @include for-desktop-up {
      background: rgb(175, 68, 35);
      content: "Desktop up";
    }

    @include for-medium-desktop-up {
      background: rgb(35, 170, 175);
      content: "Medium Desktop up";
    }

    @include for-big-desktop-up {
      background: rgb(175, 35, 140);
      content: "Big desktop up";
    }

    @include for-large-desktop-up {
      background: rgb(72, 35, 175);
      content: "Large desktop up";
    }
  }
}
