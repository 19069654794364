.related-video-img {
  width: 100%;
}

a.link {
  color: $Fifth-color;
}

.cooperation {
  line-height: 1;
  font-size: 18px;
  margin-bottom: 1rem;
  color: #6e6e6e;
}
