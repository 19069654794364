$gutter-with: 1rem;

.visualleafletNavigationTools {
  display: flex;
  flex-wrap: wrap;

  .dropDown {
    padding-bottom: 1rem;
    width: 100%;

    @include for-phone-up() {
      width: calc(50% - #{$gutter-with});
      margin: calc(#{$gutter-with} / 2);
      margin-top: 0;
    }

    @include for-tablet-portrait-up {
      width: calc(50% - #{$gutter-with});
      margin-top: 0;
    }

    @include for-tablet-landscape-up {
      width: calc(33.3333% - #{$gutter-with});
      margin-top: 0;
    }

    @include for-desktop-up {
      width: calc(33.3333% - #{$gutter-with});
      margin-top: 0;
    }

    @include for-medium-desktop-up {
      width: calc(33.33333% - #{$gutter-with});
      margin-top: 0;
    }

    @include for-big-desktop-up {
      width: calc(33.3333% - #{$gutter-with});
      margin-top: 0;
    }

    .slectBox {
      display: inline-block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 1.75rem 0.375rem 0.75rem;
      color: #495057;
      vertical-align: middle;
      background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 80" class="svg-triangle"><polygon points="0,20 100,20 50,70" fill="%23ff4370"/></svg>');
      background-size: 1rem;
      background-color: #fff;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      appearance: none;
    }
  }

  .visualleafletSearch {
    width: 100%;

    @include for-phone-up {
      margin: calc(#{$gutter-with} / 2);
    }

    @include for-tablet-portrait-up {
      margin: calc(#{$gutter-with} / 2);
    }

    @include for-tablet-landscape-up {
      width: calc(33.3333% - #{$gutter-with});
      margin-top: 1.8rem;
    }

    @include for-desktop-up {
      width: calc(33.333% - #{$gutter-with});
    }

    @include for-medium-desktop-up {
      width: calc(33.33333% - #{$gutter-with});
    }

    @include for-big-desktop-up {
      width: calc(33.3333% - #{$gutter-with});
    }

    .input-group {
      .input-group-append {
        button {
          color: white;
          background: $Fifth-color;

          &:hover {
            background: $Seventh-color;
          }
        }
      }
    }
  }
}

.beeldsluiters-container {
  @include for-phone-up() {
    margin: -0.5rem;
  }
}

.beeldsluiters {
  @include for-phone-up() {
    display: flex;
    flex-wrap: wrap;
  }

  .visualleafletCart {
    background: #fff;
    border-radius: 10px;
    padding: 0.6rem 1rem;
    margin: 1rem 0;
    position: relative;

    @include for-phone-up() {
      width: calc(100% - #{$gutter-with});
      margin: calc(#{$gutter-with} / 2);
      padding: 1rem 1.7rem;
    }

    @include for-tablet-portrait-up {
      width: calc(100% - #{$gutter-with});
    }

    @include for-tablet-landscape-up {
      width: calc(50% - #{$gutter-with});
    }

    @include for-desktop-up {
      width: calc(33.3333% - #{$gutter-with});

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.3) 0 5px 15px;
      }
    }

    @include for-medium-desktop-up {
      width: calc(33.3333% - #{$gutter-with});
    }

    @include for-big-desktop-up {
      width: calc(33.3333% - #{$gutter-with});
    }

    .leaflet-title {
      color: $Fifth-color;
    }

    .back-button {
      svg {
        padding-top: 0.25rem;
      }
    }

    .visualleaflet-indication-block {
      min-height: 3rem;
      font-size: 0.9rem;

      @include for-desktop-up {
        font-size: 0.8rem;
      }
    }

    .thumbnail-container {
      position: relative;
      cursor: pointer;

      &:hover .floating-thumbnail {
        opacity: 1;
      }

      .container-carousel {
        display: flex;

        .carousel-slider {
          .visual-carousel-item {
            .thumbnail {
              width: 100%;
            }
          }
        }
      }

      .floating-thumbnail {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: opacity 1s ease-out;

        .thumbnail {
          height: 100%;
        }
      }
    }

    .leaflet-text {
      color: $First-color;
      padding: 1rem 0 1.6rem;

      @include for-phone-up {
        padding: 1rem 0 2rem;
      }

      .read_more {
        color: $Fifth-color;
        cursor: pointer;
      }
    }

    .leaflet-button {
      position: absolute;
      bottom: 1.2rem;
      left: 1rem;
      padding: 0;
      border: none;
      background: none;
      color: $Seventh-color;

      @include for-phone-up {
        bottom: 1.7rem;
        left: 1.7rem;
      }

      .svg-inline--fa {
        vertical-align: -0.225em;
      }
    }
  }
}
